<template>
  <div class="contact-us" id="contact-us">
    <div class="container is-max-widescreen">
      <div class="contact-us-overlay">

        <div class="columns is-mobile">
          <div class="column is-6-tablet contact-us-text">
            <h2 class="content-title">{{ $t('start_now.tagline') }}</h2>
            <h2 class="content-title">- {{ $t('contact_us') }}</h2>
          </div>
          <div class="column is-6-tablet mx-auto">
            <div class="card">
              <div class="card-content">
<!--                <h2 class="content-title mb-0">{{ $t('start_now.tagline') }}</h2>-->
<!--                <h2 class="content-title mb-4">{{ $t('contact_us') }}</h2>-->
                <ValidationObserver ref="form">
                  <form @submit.prevent="handleSendMessage">
                    <ValidationProvider :name="$t('contact.product')" rules="required" v-slot="{ errors }">
                      <b-field :label="$t('contact.product')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-select v-model="form.product">
                          <option value="Permintaan Panen">{{ $t('contact.harvest_request') }}</option>
                          <option value="Others">{{ $t('others') }}</option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('contact.name')" rules="required" v-slot="{ errors }">
                      <b-field :label="$t('contact.name')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input v-model="form.name"></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('contact.phone')" rules="required" v-slot="{ errors }">
                      <b-field :label="$t('contact.phone')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <vue-phone-number-input
                            color="#128FE3"
                            valid-color="#45BE7D"
                            error-color="#FF4060"
                            default-country-code="ID"
                            v-model="phone" @update="handleInputPhoneNumber" :placeholder="$t('contact.phone')"/>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider name="Email" rules="email" v-slot="{ errors }">
                      <b-field label="Email (Opsional)" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input v-model="form.email"></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('contact.company')" rules="" v-slot="{ errors }">
                      <b-field :label="$t('contact.company')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input v-model="form.company_name"></b-input>
                      </b-field>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('contact.message')" rules="required" v-slot="{ errors }">
                      <b-field :label="$t('contact.message')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                        <b-input type="textarea" v-model="form.message"></b-input>
                      </b-field>
                    </ValidationProvider>

                    <div class="has-text-right mt-5">
                      <b-button type="is-info" native-type="submit">{{ $t('contact.send') }}</b-button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <b-loading is-full-page v-model="isLoading"></b-loading>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: "ContactUs",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      isLoading: false,
      form: {
        product: '',
        message: this.$t('contact.message_example'),
      },
      phone: null,
    }
  },
  methods: {
    ...mapActions([
      'addMessage',
    ]),

    handleInputPhoneNumber(payload) {
      this.form.phone = payload.e164;
    },

    handleSendMessage() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.addMessage(this.form).then(() => {
            this.form = {}
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.contact-us {
  //background-color: #c9e2ff;
  position: relative;
  background-image: url("/assets/img/banner/banner.jpg");
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }

  .contact-us-overlay {
    padding: 50px 0;
  }

  .contact-us-text {
    padding-top: 6rem;

    @media screen and (max-width: 768px) {
      padding-top: 2rem;
    }
  }

  .content-title {
    color: #FFFFFF !important;
    font-size: 48px;
    //color: #204c70 !important;
    //font-size: 24px;
    @media screen and (max-width: 768px) {
      font-size: 36px;
      text-align: center;
    }
  }

  .card {
    border-radius: 14px;
  }

  .card-content {
    padding: 3rem;
    @media screen and (max-width: 768px) {
      padding: 2rem;
    }
  }

  .wave {
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    display: flex;
    transform: translateY(-100%);
    z-index: 1;
  }
}

</style>
