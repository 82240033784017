var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-us",attrs:{"id":"contact-us"}},[_c('div',{staticClass:"container is-max-widescreen"},[_c('div',{staticClass:"contact-us-overlay"},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column is-6-tablet contact-us-text"},[_c('h2',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.$t('start_now.tagline')))]),_c('h2',{staticClass:"content-title"},[_vm._v("- "+_vm._s(_vm.$t('contact_us')))])]),_c('div',{staticClass:"column is-6-tablet mx-auto"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSendMessage.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.product'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('contact.product'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-select',{model:{value:(_vm.form.product),callback:function ($$v) {_vm.$set(_vm.form, "product", $$v)},expression:"form.product"}},[_c('option',{attrs:{"value":"Permintaan Panen"}},[_vm._v(_vm._s(_vm.$t('contact.harvest_request')))]),_c('option',{attrs:{"value":"Others"}},[_vm._v(_vm._s(_vm.$t('others')))])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('contact.name'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('contact.phone'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('vue-phone-number-input',{attrs:{"color":"#128FE3","valid-color":"#45BE7D","error-color":"#FF4060","default-country-code":"ID","placeholder":_vm.$t('contact.phone')},on:{"update":_vm.handleInputPhoneNumber},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Email (Opsional)","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.company'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('contact.company'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('contact.message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('contact.message'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)]}}])}),_c('div',{staticClass:"has-text-right mt-5"},[_c('b-button',{attrs:{"type":"is-info","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('contact.send')))])],1)],1)])],1)])])])])]),_c('b-loading',{attrs:{"is-full-page":""},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }